import { login } from "../config/config";
import style from "./style";


export default function createButton(){

  var styleSheet = document.createElement("style")
  styleSheet.innerHTML = style.css;
  document.head.appendChild(styleSheet)

  // 1. Create the button
  var button = document.createElement("button");
  button.innerHTML = "Login com CPQD iD";
  button.className = "cpqdid-sigin-button";
  
  // 2. Add event handler
  button.addEventListener ("click", function() {
    login();
  });
  
  // 3. Append somewhere
  var container = document.getElementsByClassName("cpqdid-signin");
  if (container.length > 0) {        
    container[0].appendChild(button);  
  }
}

