import Keycloak from "keycloak-js";
import { removeAuthentication } from "../service/auth-server-caller";
import authConfig from "./auth-config";

var _keycloak;

export async function keycloakInit(initParams) {
    if (initParams.clientId != null) {
        authConfig.config.clientId = initParams.clientId;
        authConfig.onSignIn = initParams.onSignIn;
        if (initParams.loginPopup != null) {
            authConfig.loginPopup = initParams.loginPopup;
        }
        if (_keycloak == null) {
            _keycloak = Keycloak(authConfig.config);
            _keycloak.onAuthSuccess = onSignIn;
            _keycloak
            .init()
            .then(function (authenticated) {
                console.log(authenticated ? "authenticated" : "not authenticated");
            })
            .catch(function () {
                alert('Failed to initialize authentication: ' + err);
            });
        }
    }
}

export function doLogin(initParams) {
    if (_keycloak != null) {
        if (authConfig.loginPopup && !initParams.popupResult) {
            popupLogin();
        } else {
            redirectLogin(initParams);
        }
    } else {
        alert('Error: Keycloak not initialized');      
    } 
}

export async function doLogout(initParams){
    var getUrl = window.location;
    var baseUrl = getUrl .protocol + "//" + getUrl.host;
    console.log(baseUrl);
    if (initParams.clientId != null) {
        authConfig.config.clientId = initParams.clientId;

        if (_keycloak == null) {
            _keycloak = await Keycloak(authConfig.config);
            await _keycloak.init().then(() => {
                _keycloak.logout({redirectUri: baseUrl});
            })
        }
    }
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function popupLogin() {
    var redirectUrl = authConfig.scriptSrc.substr(0, authConfig.scriptSrc.lastIndexOf('/')) + "/returnToOpener.html";
    var loginPath = _keycloak.createLoginUrl({ redirectUri: redirectUrl });
    var w = 560, h = 600;
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(loginPath, "cpqdidLoginPopup-"+authConfig.config.clientId, 
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
    );

}

async function redirectLogin(initParams) {
    authConfig.initOptions.redirectUri = initParams.redirectUri;
    _keycloak.login(authConfig.initOptions);
}

export class UserProfile {

    idToken;
    email;
    name;
    cpf;

    constructor(idToken, email, name, cpf) {
        this.idToken = idToken;
        this.email = email;
        this.name = name;
        this.cpf = cpf;
    }

    getEmail() {
        return this.email;
    }

    getIdToken() {
        return this.idToken;
    }

    getName() {
        return this.name;
    }

    getCpf() {
        return this.cpf;
    }

}

function onSignIn(){
    if (authConfig.onSignIn != null) {
        _keycloak.loadUserInfo().then(function(info) {        
            var profile = new UserProfile(_keycloak.idToken, info.email);
            authConfig.onSignIn(profile);
        }).catch(function(error) {
            console.error('Failed to load user info', error);
        });
    }    
}

export async function getProfileUser(){
    if (_keycloak != null) {
        var profile = await _keycloak.loadUserInfo().then(function(info) {  
            var userProfile = new UserProfile(_keycloak.token, info.email, info.name, info.cpf);
            return userProfile;
        }).catch(function(error) {
            console.error('Failed to load user info', error);
        });
        return profile;    
    }
}
