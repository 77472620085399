import { UserProfile } from "./authentication/authentication";
import createButton from "./button/button";
import { loadConfigTags, initKeycloak } from "./config/config";

export { init, login, logout, getInitParams, getProfile } from "./config/config";

createButton();
loadConfigTags();
initKeycloak();

window.addEventListener("message", event => {
    if (event.data.source == "cpqdidLoginPopup") {
        if (CPQDID.getInitParams().onSignIn !== undefined) {
            CPQDID.getInitParams().onSignIn(Object.assign(new UserProfile(), event.data.profile));
        } else {
            CPQDID.login(true);
        }
    }
});