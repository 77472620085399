import { doLogin, doLogout, keycloakInit, getProfileUser } from "../authentication/authentication";

var _initparams = {};

export async function init(initparams){
    _initparams = initparams;
    await keycloakInit(_initparams);
}

export function login(popupResult){
    var params = { ..._initparams };
    params.popupResult = popupResult;    
    doLogin(params);
}

export function logout(initparams){
    _initparams = initparams;
    doLogout(_initparams);
}

export async function getProfile(){
   var profile = await getProfileUser();
   return profile;
}

export function initKeycloak() {
    keycloakInit(_initparams);
}

export function getInitParams() {
    return _initparams;
}


export function loadConfigTags() {
    var metas = document.getElementsByTagName("meta");
    for (var i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === "cpqdid-clientid") {
            _initparams.clientId = metas[i].getAttribute("content");
        } else if (metas[i].getAttribute("name") === "cpqdid-redirecturi") {
            _initparams.redirectUri = metas[i].getAttribute("content");
        } else if (metas[i].getAttribute("name") === "cpqdid-onsignin") {
            var functionName = metas[i].getAttribute("content");
            if (functionName != null && functionName != "") {
                _initparams.onSignIn = window[functionName];
            }
        }
    }
}

