export default 
    {
        css: `        
            .cpqdid-sigin-button {
                background: #000;
                border-radius: 28px;
                display: inline-block;
                color: #fff;
                font-family: Roboto, arial, sans-serif;
                text-decoration: none;
                font-size: 15px;
                padding: 10px 20px;
                margin-top: 10px;
                font-weight: 700;
            }
        
            .cpqdid-sigin-button:hover {                
                cursor: pointer;
            }
        `
    }
