const config = {
    url: process.env.URL_KEYCLOAK_SERVER,
    realm: process.env.REALM,
    clientId: '',
}

const initOptions = {
    responseType: 'code',
    onLoad: "check-sso",
    scope: 'openid', 
    redirectUri: '',
    checkLoginIframe: true,
    promiseType: 'native'
}

export default {
    config: config,
    initOptions: initOptions,
    scriptSrc: document.currentScript.src,
    loginPopup: false
}
